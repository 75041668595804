import { Typography } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
  <>
    <Helmet title="404: Not found" />
    <Typography variant="h1" component="h1" sx={{ my: 4 }}>
      Sidan kunde inte hittas
    </Typography>
    <p>
      Ajdå, du har hamnat på en sida som inte finns. Prova att gå tillbaka till{' '}
      <Link to="/">startsidan</Link> och klicka dig fram därifrån.
    </p>
  </>
);

export default NotFoundPage;
